import { ConsultationModel } from '@/models/consultation-model'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { consultationGenrePediatrie } from '../../Dossier/Constants'
import { headers } from '../../Static'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import Alert from '@/components/shared/Alert.vue'
import { AuthService } from '@/services/auth-service'
import Commons from '@/components/shared/Helpers/commons'
import { defaultItemsPerPage, defaultItemsPerPageWithAll } from '@/shared/constants/Constants'
import { ModuleAuthorisationManager } from '@/services/module-authorisation-manager'

@Component({
  components: {
    Alert,
    Confirm
  }
})
export default class Consultations extends Vue {
  private authService = AuthService.getInstance()

  private consultationGenres = consultationGenrePediatrie
  private selectedItem = {}
  public headers = headers
  @Prop()
  public type!: number

  @Prop()
  public isLoading!: boolean

  @Prop()
  public data!: ConsultationModel[]

  @Prop({ type: Array, required: false, default: () => { return [] } })
  public errorMessages!: string[]

  public showConfirm = false
  public defaultItemsPerPageWithAll = defaultItemsPerPageWithAll
  public defaultItemsPerPage = defaultItemsPerPage
  public hasOverviewAccess = false

  public mounted () {
    this.hasOverviewAccess = ModuleAuthorisationManager.HasAccess('dossier.dossierPatientOverview')
  }

  public convertGenreIdToName (genreId: number) {
    if (genreId) {
      return this.consultationGenres.find(x => x.value === genreId)?.text
    }
  }

  public canEditItem (consultation: ConsultationModel) {
    return !consultation.isLinkedToInvoice
  }

  public canDeleteItem (consultation: ConsultationModel) {
    return !consultation.isLinkedToInvoice && (this.authService.Roles.includes('NursingRecordsDelete') || (consultation.date ? Commons.GetDate(consultation.date)! >= Commons.TodayDate() : false))
  }

  public editItem (consultation: ConsultationModel) {
    this.$emit('edit', consultation)
  }

  public displayDeleteConfirmDialog (consultation: ConsultationModel) {
    this.showConfirm = true
    this.selectedItem = consultation
  }

  public async confirmDeleteCallback (value: boolean) {
    this.showConfirm = false
    if (value) {
      this.$emit('delete', this.selectedItem)
    }
  }

  public goHome () {
    this.$router.push({ name: 'patient' })
  }

  public hideAlert () {
    this.$emit('clearAlert')
  }
}
