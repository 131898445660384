import { RepasModel } from '@/models/anamnese-alimentaire-model'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { AuthService } from '@/services/auth-service'
import { ErrorService } from '@/services/error.service'
import { PatientService } from '@/services/patient-service'
import { Subscription } from 'rxjs'
import { Component, Vue } from 'vue-property-decorator'
import { periode } from '../../Dossier/Constants'
import Alert from '@/components/shared/Alert.vue'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import EmptyTimeLine from '@/components/shared/EmptyTimeLine.vue'
import { ValidationObserver } from 'vee-validate'
import { AxiosResponse } from 'axios'
import Commons from '@/components/shared/Helpers/commons'

@Component({
  components: {
    ValidationObserver,
    Alert,
    Confirm,
    EmptyTimeLine
  }
})

export default class Repas extends Vue {
  private patientService = PatientService.getInstance()
  private userService = AuthService.getInstance()
  private subscriptions: Subscription[] = []
  public repasPeriodes = periode
  public repasItems: RepasModel[] = []
  public repasModel: RepasModel = { date: Commons.GetTodayFormatted() }
  public repasLoading = false
  public isEdit = false
  public errorMessages: string[] = []
  public selectedDossier!: dossierPatientModel
  public isdialogSaving = false
  public showDialog = false
  public showConfirm = false

  public mounted () {
    this.subscriptions.push(this.patientService.dossierSelected$.subscribe((dossier: dossierPatientModel) => {
      this.selectedDossier = dossier
    }))
    this.getRepasItems()
  }

  public getRepasItems () {
    this.repasLoading = true
    this.patientService.getRepasItems(this.selectedDossier.guid)
      .then(repases => {
        this.repasItems = repases
      })
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      })
      .finally(() => {
        this.repasLoading = false
      })
  }

  public isCollation (period: number) {
    return (period === 1 || period === 3 || period === 5)
  }

  public getPeriodeName (period: number) {
    return periode.find(x => x.value === period)?.text
  }

  public async saveRepasItem () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.isdialogSaving = true
      this.errorMessages = []
      const results = await this.patientService.addRepasItem(this.selectedDossier.guid, this.repasModel)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.isdialogSaving = false
        })
      if ((results as AxiosResponse<RepasModel>)?.status === 200) {
        this.errorMessages = []
        this.resetdialog()
        this.showDialog = false
        this.getRepasItems()
      }
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  public editRepasItem (item: RepasModel) {
    this.repasModel = item
    this.showDialog = true
    this.isEdit = true
  }

  public confirmRepasItemDelete (repas: RepasModel) {
    this.showConfirm = true
    this.repasModel = repas
  }

  public async confirmDeleteCallback (value: boolean) {
    this.showConfirm = false
    if (value) {
      this.repasLoading = true
      const results = await this.patientService.deleteRepas(this.repasModel.id as string)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.repasLoading = false
        })
      if ((results as AxiosResponse<RepasModel>)?.status === 200) {
        this.errorMessages = []
        this.getRepasItems()
      }
    }
    this.resetdialog()
  }

  private resetdialog () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    if (observer) observer.reset()
    this.repasModel = { date: Commons.GetTodayFormatted() }
  }

  public closeDialog () {
    this.resetdialog()
    this.showDialog = false
    this.isEdit = false
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public $destroyed () {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe()
    })
  }
}
