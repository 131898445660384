import { Component, Vue } from 'vue-property-decorator'
import { PatientService } from '@/services/patient-service'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { Subscription } from 'rxjs'
import { ErrorService } from '@/services/error.service'
import { HistoirePonderaleModel } from '@/models/anamnese-alimentaire-model'
import Alert from '@/components/shared/Alert.vue'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import EmptyTimeLine from '@/components/shared/EmptyTimeLine.vue'
import { ValidationObserver } from 'vee-validate'
import { AxiosResponse } from 'axios'
import Commons from '@/components/shared/Helpers/commons'

@Component({
  components: {
    Alert,
    Confirm,
    EmptyTimeLine,
    ValidationObserver
  }
})
export default class HistoirePonderale extends Vue {
  private patientService = PatientService.getInstance()
  private subscriptions: Subscription[] = []
  public histoirePonderaleitems: HistoirePonderaleModel[] = []
  public histoirePonderalModel: HistoirePonderaleModel = { date: Commons.GetTodayFormatted() }
  public histoireLoading = false
  public isEdit = false
  public errorMessages: string[] = []
  public selectedDossier!: dossierPatientModel
  public isdialogSaving = false
  public showDialog = false
  public showConfirm = false

  public mounted () {
    this.subscriptions.push(this.patientService.dossierSelected$.subscribe((dossier: dossierPatientModel) => {
      this.selectedDossier = dossier
    }))
    this.getHistoirePonderales()
  }

  public getHistoirePonderales () {
    this.histoireLoading = true
    this.patientService.getHistoirePonderale(this.selectedDossier.guid)
      .then(histoirePonderaleList => {
        this.histoirePonderaleitems = histoirePonderaleList
      })
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      })
      .finally(() => {
        this.histoireLoading = false
      })
  }

  public async saveObservation () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.isdialogSaving = true
      this.errorMessages = []
      const results = await this.patientService.addObservation(this.selectedDossier.guid, this.histoirePonderalModel)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.isdialogSaving = false
        })
      if ((results as AxiosResponse<any>)?.status === 200) {
        this.resetdialog()
        this.showDialog = false
        this.getHistoirePonderales()
      }
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  public editObservation (item: HistoirePonderaleModel) {
    this.histoirePonderalModel = { ...item }
    this.showDialog = true
    this.isEdit = true
  }

  public confirmObservationItemDelete (observation: HistoirePonderaleModel) {
    this.showConfirm = true
    this.histoirePonderalModel = observation
  }

  public async confirmDeleteCallback (value: boolean) {
    this.showConfirm = false
    if (value) {
      this.histoireLoading = true
      const results = await this.patientService.deleteObservation(this.histoirePonderalModel.id as string)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.histoireLoading = false
        })
      if ((results as AxiosResponse<any>)?.status === 200) {
        this.errorMessages = []
        this.getHistoirePonderales()
      }
    }
    this.resetdialog()
  }

  private resetdialog () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    if (observer) observer.reset()
    this.histoirePonderalModel = { date: Commons.GetTodayFormatted() }
    this.errorMessages = []
  }

  public closeDialog () {
    this.resetdialog()
    this.showDialog = false
    this.isEdit = false
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public $destroyed () {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe()
    })
  }
}
